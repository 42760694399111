import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import SEO from "../components/SEO"

const Error = () => {
  return (
    <Layout>
    <SEO title='ERROR 404'></SEO>
    <main className="error-page">
      <div className="error-container">
        <h1>Oops!</h1>
        <h1>No se encontro la pagina</h1>
        <Link to="/" className="btn">
          Regresar
        </Link>
      </div>
    </main>
    </Layout>
  )
}

export default Error
